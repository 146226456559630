<template>
<div id="pageTable">
    <v-container grid-list-xl fluid>
        <v-card>
            <v-layout class="d-flex flex-lg-row flex-md-column flex-sm-column" wrap pa-3>
                <v-flex lg12 md12 sm12 xs12>
                    <v-text-field v-model="staff.Username" ref="username" label="User Name" class="input-group--focused" :rules="nameRules" error-count="2"></v-text-field>
                </v-flex>
                <v-flex lg12 md12 sm12 xs12>
                    <v-text-field v-model="staff.Email" label="Email" class="input-group--focused"></v-text-field>
                </v-flex>
                <v-flex lg12 md12 sm12 xs12>
                    <v-text-field v-model="staff.FullName" label="Họ tên" class="input-group--focused"></v-text-field>
                </v-flex>
                <!-- <v-flex lg12 md12 sm12 xs12>
                    <v-select v-model="staff.RoleID" :items="role_list" item-text="name" item-value="value" label="Quyền hạn" class="input-group--focused"></v-select>
                </v-flex> -->
                <v-flex lg12 md12 sm12 xs12>
                    <div class="subtitle-2 mb-4">Quyền hạn</div>
                </v-flex>
                <v-divider></v-divider>
                <v-flex lg4 md6 sm12><v-switch v-model="roleEdit.admin" label="Admin" class="input-group--focused"></v-switch></v-flex>
                <v-flex lg4 md6 sm12><v-switch v-model="roleEdit.purchase" label="CSKH" class="input-group--focused" :disabled="roleEdit.admin == true"></v-switch></v-flex>
                <!-- <v-flex lg4 md6 sm12><v-switch v-model="roleEdit.customerService" label="Tiếng Trung" class="input-group--focused" :disabled="roleEdit.admin == true"></v-switch></v-flex> -->
                <v-flex lg4 md6 sm12><v-switch v-model="roleEdit.storeVN" label="Kho VN" class="input-group--focused" :disabled="roleEdit.admin == true"></v-switch></v-flex>
                <v-flex lg4 md6 sm12><v-switch v-model="roleEdit.storeCN" label="Kho TQ" class="input-group--focused" :disabled="roleEdit.admin == true"></v-switch></v-flex>
                <v-flex lg4 md6 sm12><v-switch v-model="roleEdit.payment" label="Kế toán" class="input-group--focused" :disabled="roleEdit.admin == true"></v-switch></v-flex>
                <v-flex lg12 md12 sm12 xs12 v-if="roleEdit.customerService">
                    <v-select v-model="staff.ReceivePlaceID" :items="warehouses" item-text="Name" item-value="ID" label="Kho hàng" class="input-group--focused"></v-select>
                </v-flex>
                <v-flex lg12 md12 sm12 xs12 v-if="roleEdit.storeCN">
                    <v-select v-model="staff.ChinaStoreID" :items="chinaStores" item-text="Name" item-value="ID" label="Kho TQ" class="input-group--focused"></v-select>
                </v-flex>
                <v-flex lg12 md12 sm12 xs12>
                    <div class="subtitle-2 mb-4">Trạng thái</div>
                </v-flex>
                <v-flex lg12 md12 sm12 xs12>
                    <v-switch v-model="staff.is_active" label="Kích hoạt tài khoản" class="input-group--focused"></v-switch>
                </v-flex>
                <v-flex lg12 md12 sm12 v-if="roleEdit.storeCN">
                    <div class="subtitle-2 mb-4">Giao diện kho tiếng Trung</div>
                </v-flex>
                <v-flex lg12 md12 sm12 v-if="roleEdit.storeCN">
                    <v-switch v-model="staff.IsChinessLanguage" label="Hiển thị tiếng Trung" class="input-group--focused"></v-switch>
                </v-flex>
            </v-layout>
        </v-card>
    </v-container>
</div>
</template>

<script>
import { mapState } from "vuex";
import md5 from "md5";
import configs from "../../configs";
export default {
    data() {
        let roles = this.$store.state.roles.all.data.map(obj =>{ 
            var rObj = {};
            rObj['value'] = obj.ID;
            rObj['name'] = obj.RoleName;
            return rObj;
        });
        return {
            firstLoad: true,
            role_list: roles,            
            roleEdit: {
                admin: false,
                purchase: false,
                customerService: false,
                storeVN: false,
                storeCN: false,
                payment: false
            },
            nameRules: [
                v => (v || '').indexOf(' ') < 0 || 'Tên đăng nhập không được chứa dấu cách',
                v => !(/(?=.*[A-Z])/.test(v)) || 'Tên đăng nhập không được viết hoa',
                v => /^[\x00-\x7F]*$/.test(v) || 'Tên đăng nhập chỉ bao gồm chữ cái a-z và số 0-9',
            ]
        }
    },
    computed: {
        ...mapState({
            loading: state => state.staff.loading,
            staff: state => state.staff.selected,
            warehouses: state => state.commons.stores,
            chinaStores: (state) => state.commons.chinaStores,
        }),
        modifyId() {
            return this.$route.params.Pid;
        },
        csRoleID() {
            return configs.ROLE_TYPE.CustomerService;
        },
    },
    watch: {
        modifyId() {
            this.refreshData();
        },
        staff() {
            if(this.firstLoad){                
                this.firstLoad = false;
                this.roleEdit.admin = (this.staff.RoleType & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin;
                this.roleEdit.purchase = (this.staff.RoleType & configs.ROLE_TYPE.Purchase) == configs.ROLE_TYPE.Purchase;
                this.roleEdit.customerService = (this.staff.RoleType & configs.ROLE_TYPE.CustomerService) == configs.ROLE_TYPE.CustomerService;
                this.roleEdit.storeVN = (this.staff.RoleType & configs.ROLE_TYPE.StoreVN) == configs.ROLE_TYPE.StoreVN;
                this.roleEdit.storeCN = (this.staff.RoleType & configs.ROLE_TYPE.StoreTQ) == configs.ROLE_TYPE.StoreTQ;
                this.roleEdit.payment = (this.staff.RoleType & configs.ROLE_TYPE.Payment) == configs.ROLE_TYPE.Payment;
            }
        },
        roleEdit: {
            handler: function (val, oldVal) {
                if(!this.firstLoad) {
                    this.staff.RoleType = 0;
                    if(val.admin) {
                        this.staff.RoleType = this.staff.RoleType | configs.ROLE_TYPE.Admin;
                    }
                    else {
                        if(val.purchase) {
                            this.staff.RoleType = this.staff.RoleType | configs.ROLE_TYPE.Purchase;
                        }
                        if(val.customerService) {
                            this.staff.RoleType = this.staff.RoleType | configs.ROLE_TYPE.CustomerService;
                        }
                        if(val.storeVN) {
                            this.staff.RoleType = this.staff.RoleType | configs.ROLE_TYPE.StoreVN;
                        }
                        if(val.storeCN) {
                            this.staff.RoleType = this.staff.RoleType | configs.ROLE_TYPE.StoreTQ;
                        }
                        if(val.payment) {
                            this.staff.RoleType = this.staff.RoleType | configs.ROLE_TYPE.Payment;
                        }
                    }
                }
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        refreshData() {
            if (this.modifyId > 0) {
                this.$store.dispatch("staff/getDetail", this.modifyId);
            }
            else {
                this.$store.commit("staff/setDetail", { data: {
                        ID: 0,
                        Username: '',
                        Email: '',
                        Password: md5('123456'),
                        RoleType: 0,
                        ReceivePlaceID: 1,
                        is_active: 0,
                        is_admin: 0
                    }
                })
            }
            this.$nextTick(() => this.$refs.username.focus());
        },
        saveData() {
            this.$store.dispatch("staff/setDetail", {
                data: this.staff,
                id: this.staff.ID
            });
            window.getApp.$emit("APP_NAVIGATE", "StaffList");
        },
    },
    mounted() {
        this.$store.dispatch("commons/getAllStores");
        this.$store.dispatch("commons/getAllChinaStores");
        this.refreshData();
        this.$root.$emit('REQUEST_ROOT_OPTION', {});
        this.$root.$on("REQUEST_SAVE_DATA", this.saveData);
        if (this.$route.query.uid && this.$route.query.uid !== '') {
            this.staff.UID = Number(this.$route.query.uid);
        }
    },
    beforeDestroy() {
        this.$root.$off("REQUEST_SAVE_DATA", this.saveData);
    },
};
</script>